import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start();
import 'iconify-icon';
import Swiper, {Navigation, Pagination} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'flowbite';
import Swal from 'sweetalert2';
import $ from 'jquery';
import preline from 'preline';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

// $('div.items').hover(function(){
//   var img = $(this).data('image');
//   console.log(img);
// });
$('#owl-carousel').owlCarousel({
  loop: true,
  margin: 30,
  dots: false,
  nav: false,
  autoplay:true,
  autoplayTimeout:2000,
  autoplayHoverPause:true,
  items : 4,
  responsive: {
  0: {
    items: 2
  },

  600: {
    items: 3
  },

  1024: {
    items: 4
  },

  1366: {
    items: 4
  }
}
})

const homeSwiper = new Swiper('.home-swiper', {
  modules: [Navigation, Pagination],
  loop: true,
  navigation: {
    nextEl: '.home-swiper-next',
    prevEl: '.home-swiper-prev',
  },
});

const swiper1 = new Swiper('.swiper1', {
  loop: true,
  navigation:false,
  spaceBetween:30,
  breakpoints:{
    0:{
        slidesPerView:1,
    },
    480:{
      slidesPerView:1,
    },
    768:{
      slidesPerView:2,
    },
    992:{
      slidesPerView:3,
    }
  }
});
const swiper2 = new Swiper('.swiper2', {
  loop: true,
  navigation:false,
  spaceBetween:30,

  breakpoints:{
    0:{
        slidesPerView:1,
    },
    480:{
      slidesPerView:1,
    },
    768:{
      slidesPerView:2,
    },
    992:{
      slidesPerView:3,
    }
  }
});

const homeOurServices = new Swiper('.home-our-services', {
  modules: [Navigation, Pagination],
  loop: true,
  spaceBetween:10,
  breakpoints:{
    0:{
        slidesPerView:1
    },
    480:{
      slidesPerView:2
    },
    768:{
      slidesPerView:3
    },
    992:{
      slidesPerView:4
    }
  }
});

$('form#news-form').submit(function(){
  $.post($(this).attr('action'), $(this).serialize())
    .done(function(res){
      Swal.fire({
        icon: 'success',
        title: 'Tebrikler!',
        text: res.message,
      })
    })
    .fail(function(xhr, status, error){
      var obj = JSON.parse(xhr.responseText);
      obj = obj.errors;
      var errors = '';
      $.each(obj, function(key,val){
        errors += ' - '+val+"\n";
      });
      Swal.fire({
        icon: 'error',
        title: 'Hata!',
        text: errors,
      })
    });
  return false;
});

$('.scroll-bottom').click(function(){
  $("html, body").animate({ scrollTop: $('#scroll').offset().top }, 600);

  return false;
});

AOS.init();


$('#contact_form').submit(function(event) {
  event.preventDefault();
  grecaptcha.ready(function() {
      grecaptcha.execute(key, {
          action: 'subscribe_newsletter'
      }).then(function(token) {
          $('#contact_form').prepend('<input type="hidden" name="token" value="' +
              token + '">');
          $('#contact_form').unbind('submit').submit();
      });;
  });
});
